import { ContentUsageEntity } from "@/content-usage/__generated__/ContentModulesDragDropProvider_UpdateContentUsageMutation.graphql"
import {
  CloneContentModuleDestinationTab,
  CloneContentModuleFormState,
} from "@/content-usage/buttons/CloneContentModuleButton"
import CollectionDropdownItem from "@/content-usage/forms/CollectionDropdownItem"
import { CloneContentModuleFormFieldsFragment$key } from "@/content-usage/forms/__generated__/CloneContentModuleFormFieldsFragment.graphql"
import { useLabel } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import ProductDropdownItem from "@/product/common/ProductDropdownItem"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoFormControl,
  DiscoFormLabel,
  DiscoInput,
  DiscoSection,
  DiscoSelect,
  DiscoText,
  SelectOption,
} from "@disco-ui"
import DiscoDateTimePickerV1 from "@disco-ui/date/DiscoDateTimePickerV1"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { addDays } from "date-fns"
import { capitalize } from "lodash"
import { observer } from "mobx-react-lite"
import { graphql, useFragment } from "react-relay"

interface Props {
  moduleUsageEntity: ContentUsageEntity
  form: FormStore<CloneContentModuleFormState>
  organizationKey: CloneContentModuleFormFieldsFragment$key
}

function CloneContentModuleFormFields(props: Props) {
  const { form, organizationKey, moduleUsageEntity } = props
  const classes = useStyles()
  const experienceLabel = useLabel("experience")

  const isCurriculum = moduleUsageEntity === "curriculum"
  const isCollection = moduleUsageEntity === "collection"

  const organization = useFragment<CloneContentModuleFormFieldsFragment$key>(
    graphql`
      fragment CloneContentModuleFormFieldsFragment on Organization {
        id
        products(type: "course", filterByAdminAccess: true) {
          edges {
            node {
              id
              name
              slug
              startDate
              curriculumApps: productApps(kind: curriculum) {
                totalCount
              }
              collectionApps: productApps(kind: collection) {
                edges {
                  node {
                    id
                    customAppTitle
                    collection {
                      id
                    }
                    ...CollectionDropdownItemFragment
                  }
                }
              }
              curriculum {
                id
              }
              ...ProductDropdownItemFragment
            }
          }
        }
      }
    `,
    organizationKey
  )

  const initialProduct = { ...form.initialState.destinationProduct }
  const initialContentModuleName = form.initialState.name.split(" - Copy")[0]

  const otherExperiences = Relay.connectionToArray(organization?.products).filter(
    (product) => product.id !== initialProduct.id
  )

  const experiencesWithCollections = otherExperiences.filter(
    (product) => product.collectionApps.edges.length
  )

  const collectionApps = experiencesWithCollections.flatMap((product) =>
    Relay.connectionToArray(product.collectionApps).map((app) => {
      return {
        collectionId: app.collection!.id,
        ...app,
        product,
      }
    })
  )

  const collectionAppsById = ArrayUtils.mapBy(collectionApps, "collectionId")

  const experiencesWithCurriculums = otherExperiences.filter(
    (product) => product.curriculumApps.totalCount
  )

  const experiencesWithCurriculumsById = ArrayUtils.mapBy(
    experiencesWithCurriculums,
    "id"
  )

  return (
    <>
      <DiscoText display={"inline"} color={"text.secondary"}>
        {"Clone "}
        <DiscoText variant={"body-md-700"} component={"span"}>
          {initialContentModuleName}
        </DiscoText>
        {` within the same ${moduleUsageEntity} or another ${experienceLabel.singular}.`}
      </DiscoText>

      <DiscoTabs
        testid={"CloneContentModuleFormFields.tabs"}
        tabVariant={"grey-track"}
        classes={{ arrowsContainer: classes.tabsContainer }}
        className={classes.tabs}
        tabClassname={classes.tab}
        routes={[
          {
            label: `Within ${capitalize(moduleUsageEntity)}`,
            onClick: () => handleChangeTab("within_entity"),
            active: form.state.destinationTab === "within_entity",
            testid: "within_entity",
          },
          {
            label: `To Another ${experienceLabel.singular}`,
            onClick: () => handleChangeTab("across_experiences"),
            active: form.state.destinationTab === "across_experiences",
            testid: "across_experiences",
          },
        ]}
      />

      <DiscoSection groovyDepths={"insideCard"}>
        {form.state.destinationTab === "across_experiences" && isCollection && (
          <DiscoFormControl
            label={"Select Collection"}
            errorMessages={form.errorsByField.destinationEntityId}
          >
            {/* Collection Select */}
            <DiscoSelect
              testid={"CloneContentModuleFormFields.select"}
              placeholder={"Select Collection"}
              value={form.state.destinationCollection?.id}
              onChange={handleSelectCollection}
              disableClearable
              options={collectionApps.map(
                (collectionApp) =>
                  ({
                    value: collectionApp.collection?.id || "",
                    title: collectionApp.customAppTitle || "Collection",
                    searchable: [
                      collectionApp.customAppTitle,
                      collectionApp.product.name,
                    ],
                  } || [])
              )}
              renderOption={renderCollectionOption}
            />
          </DiscoFormControl>
        )}

        {form.state.destinationTab === "across_experiences" && isCurriculum && (
          <DiscoFormControl
            label={`Select ${experienceLabel.singular}`}
            errorMessages={form.errorsByField.destinationEntityId}
          >
            {/* Experience Select */}
            <DiscoSelect
              testid={"CloneContentModuleFormFields.select"}
              placeholder={`Select ${experienceLabel.singular}`}
              value={form.state.destinationProduct?.id}
              onChange={handleSelectExperience}
              disableClearable
              options={experiencesWithCurriculums.map(
                (experience) =>
                  ({
                    value: experience.id || "",
                    title: experience.name,
                    searchable: [experience.name, experience.slug],
                    startDate: experience.startDate,
                  } || [])
              )}
              renderOption={renderProductOption}
            />
          </DiscoFormControl>
        )}

        {/* Name */}
        <DiscoFormControl label={"Module Name"} errorMessages={form.errorsByField.name}>
          <DiscoInput
            inputProps={{
              "data-testid": "CloneContentModuleFormFields.name-input",
            }}
            title={"Module Name"}
            value={form.state.name}
            onChange={handleNameChange}
          />
        </DiscoFormControl>

        {form.state.releasedAt && (
          <DiscoFormControl>
            <DiscoDateTimePickerV1
              testid={"CloneContentModuleFormFields.released-at"}
              value={form.state.releasedAt}
              onChange={(v) => (form.state.releasedAt = v?.toISOString())}
              minDate={new Date()}
              dateSelectTitle={
                <DiscoFormLabel className={classes.dateTimePickerLabels}>
                  {"Release Date"}
                </DiscoFormLabel>
              }
              timeSelectTitle={
                <DiscoFormLabel className={classes.dateTimePickerLabels}>
                  {"Release Time"}
                </DiscoFormLabel>
              }
              variant={"two-column"}
            />
          </DiscoFormControl>
        )}
      </DiscoSection>
    </>
  )

  function handleChangeTab(tab: CloneContentModuleDestinationTab) {
    if (tab === "within_entity") {
      // Reset back to initial state
      form.state.destinationProduct = form.initialState.destinationProduct
      form.state.destinationCollection = form.initialState.destinationCollection
      form.state.destinationCurriculum = form.initialState.destinationCurriculum
      form.state.releasedAt = calculateDefaultStartDate(
        form.initialState.destinationProduct?.startDate || null
      )
    } else if (tab === "across_experiences") {
      form.state.destinationProduct = null
      form.state.destinationCollection = null
      form.state.destinationCurriculum = null
      form.state.releasedAt = null
    }
    form.state.destinationTab = tab
  }

  function handleSelectCollection(collectionId: GlobalID | null) {
    if (!collectionId) return

    const collectionApp = collectionAppsById[collectionId]

    form.state.destinationProduct = {
      id: collectionApp.product.id,
      startDate: collectionApp.product.startDate
        ? new Date(collectionApp.product.startDate)
        : null,
    }
    form.state.destinationCollection = collectionApp.collection
  }

  function renderCollectionOption(selectOption: SelectOption<GlobalID>) {
    const collectionApp = collectionAppsById[selectOption.value]

    return (
      <CollectionDropdownItem
        testid={`CollectionSelect.option.${selectOption.title}`}
        collectionAppKey={collectionApp}
      />
    )
  }

  function handleSelectExperience(productId: GlobalID | null) {
    if (!productId) return
    const { id, startDate, curriculum } = experiencesWithCurriculumsById[productId]
    form.state.destinationProduct = {
      id,
      startDate: startDate ? new Date(startDate) : null,
    }
    form.state.destinationCurriculum = curriculum
    form.state.releasedAt = calculateDefaultStartDate(
      form.state.destinationProduct.startDate
    )
  }

  function calculateDefaultStartDate(productStartDate: Date | null) {
    if (!isCurriculum) return null

    return (
      form.initialState.releasedAt ||
      productStartDate?.toISOString() ||
      addDays(new Date(), 7).toISOString()
    )
  }

  function renderProductOption(selectOption: SelectOption<GlobalID>) {
    const product = experiencesWithCurriculumsById[selectOption.value]

    return (
      <ProductDropdownItem
        testid={`ProductSelect.option.${selectOption.title}`}
        productKey={product}
      />
    )
  }

  function handleNameChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    form.state.name = e.target.value
  }
}

export default observer(CloneContentModuleFormFields)

const useStyles = makeUseStyles((theme) => ({
  tabsContainer: {
    margin: theme.spacing(1.5, 0),
    gridTemplateColumns: "1fr",
  },
  tabs: {
    justifyContent: "space-around",
  },
  tab: {
    flex: 1,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    whiteSpace: "nowrap",
    padding: theme.spacing(1, 3),
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "normal",
    },
  },
  dateTimePickerLabels: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    width: "100%",
  },
}))
